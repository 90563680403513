import React from "react";
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { get, post, ApiError } from 'aws-amplify/api';

import "../styles/main.scss";
import Sidebar from "../components/sidebar";
import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";

const NotEnrolled = () => {
    const [returnedUsers, setReturnedUsers] = useState([]);
    const [checked, setChecked] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {handleSubmit } = useForm();

    async function getNotEnrolledUsers() {
      const apiName = "users";
      const path = "/users/notenrolled";
      const requestHeaders = {
          "Access-Control-Allow-Origin": "*"
      };
      try {
        const restOperation = get({
          apiName: apiName, 
          path: path, 
          options: {
            headers: requestHeaders
          }
        });
        const {body} = await restOperation.response;
        const response = await body.json();
        return response;
      } catch (e) {
        alert('There was an error fetching the not enrolled. Please look at console (F12) and contact justin');
        console.log('Error is below');
        console.log(e);
        if (e instanceof ApiError) {
          if (e.response) {
              const { 
              statusCode, 
              body 
              } = e.response;
              console.error(`Received ${statusCode} error response with payload: ${body}`);
          }
        }
        return null;
      }
    }

    useEffect(() => {
      getNotEnrolledUsers().then((users) => {
        if(users != null) {
          setReturnedUsers(users.body.items);
        }
      });
    }, []);

    async function submitHandler(values) {
        setIsSubmitting(true);
        const arrEmails = [];
        for (const [key, value] of Object.entries(checked)) {
            if(value) {
                arrEmails.push(key);
            }
          }
        if(arrEmails.length === 0) {
            setIsSubmitting(false);
            alert('You did not check any users to resend the email to. Please check the users who should get an email before hitting Submit');
            return;
        }
        const apiName = "users";
        const path = "/users/notenrolled";
        const requestBody = {arrEmails};
        const requestHeaders = {
            "Access-Control-Allow-Origin": "*"
        };

        try {
          const restOperation = post({
            apiName: apiName, 
            path: path, 
            options: {
              body: requestBody,
              headers: requestHeaders
            }
          });
          await restOperation.response;
          //if here, status code of 200 so it is good
          alert('The emails were sent successfully.');
        } catch (e) {
          alert('There was an error and emails might not have been sent. Look at console (F12) and contact justin');
          console.log('Error is below');
          console.log(e);
          if (e instanceof ApiError) {
            if (e.response) {
                const { 
                statusCode, 
                body 
                } = e.response;
                console.error(`Received ${statusCode} error response with payload: ${body}`);
            }
          }
        } finally {
          setIsSubmitting(false);
        }
    }

    const handleChecked = id => e => {
        const { checked } = e.target;
          setChecked((values) => ({
            ...values,
            [id]: checked
          }));
      };

    return (
        <div className="container">
          <Sidebar />
          <main>
          <div>
            <h1>
              <center>Not Enrolled Users</center>
            </h1>
              <center>
                Below are the users that were originally emailed to join Addventure, but they have not yet created an account on the Enrollment Portal.
              </center>
            <hr />
            {returnedUsers && returnedUsers.length > 0 && (

            <form onSubmit={handleSubmit(submitHandler)} style={{width: "80vw"}}>
              <table className="styled-table">
                <thead>
                  <tr key="header">
                    <th>Name</th>
                    <th>Email</th>
                    <th>Company</th>
                    <th>Resend?</th>
                  </tr>
                </thead>
                <tbody>
                  {returnedUsers &&
                    returnedUsers.map((user, i) => (
                      <tr
                        key={i}
                      >
                        <td>{user.name}</td>
                        <td>{user.sk}</td>
                        <td>
                          {user.empCode}
                        </td>
                        <td>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked[i]}
                                        onClick={handleChecked(user.sk)}
                                    />
                                }
                            />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <br />
              <div style={{textAlign: "center"}}>
                {isSubmitting === true && (
                    <center><CircularProgress size={30} /></center>
                )}
                {isSubmitting !== true && (
                    <input type="submit" className="submit-button"/>
                )}
              
              
              </div>
              </form>
          )}
          </div>
          </main>
        </div>
      );
}

export default NotEnrolled;