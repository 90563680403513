import React from 'react';
import '../styles/header_non_auth.css';

const HeaderNonAuth = () => {
    return (
      <div className="header">
        <div className="logo header-left addOrange">ADDVENTURE</div>
        <div className="logo header-left addGrey">Admin Portal</div>
        
        <div className="header-right">
          <a className="active" href="#home">Home</a>
          <a href="#contact">Contact</a>
          <a href="https://www.goaddventure.com/">About</a>
        </div>
      </div>
    );
}

export default HeaderNonAuth;