import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { get, ApiError } from 'aws-amplify/api';

import Sidebar from "../components/sidebar";
import DynamoDbTable from "../components/user_details/dynamo_db_table";
import CognitoTable from "../components/user_details/cognito_table";
import TripTable from "../components/user_details/trip_table";
import TransactionsTable from "../components/user_details/transactions_table";

const UserDetails = (props) => {
  const location = useLocation();
  const uuid = location?.state?.uuid;
  const company = location?.state?.company;
  const [dynamoDB, setDynamoDB] = useState([]);
  const [cognitoData, setCognitoData] = useState({});
  const [userTrips, setUserTrips] = useState([]);
  const [userTransactions, setUserTransactions] = useState({});

  async function getUserTrips() {
    const apiName = "users";
    const path = "/trips";
    const requestHeaders = {
      "Access-Control-Allow-Origin":
        "Authorization, Access-Control-Allow-Origin,Access-Control-Allow-Headers",
    };
    const queryStringParameters = {
      uuid: uuid,
    };

    try {
      const restOperation = get({
        apiName: apiName, 
        path: path, 
        options: {
          headers: requestHeaders,
          queryParams: queryStringParameters
        }
      });
      const { body } = await restOperation.response;
      const json = await body.json();
      if (json == null || json === "[]") {
        setUserTrips(["User has no trips set yet"]);
      } else {
        const data = JSON.parse(json);
        var trips = data?.filter((trip) => trip?._deleted !== true);
        setUserTrips(trips);
      }
    } catch (e) {
      alert('There was an error fetching the trips. Please look at console (F12) and contact Justin');
      console.log('There was an error fetching the trips');
      console.log(e);
      if (e instanceof ApiError) {
        if (e.response) {
          const { 
            statusCode, 
            body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
        }
      }
    }

  }

  async function getTransactions(startDate, endDate) {
    const apiName = "users";
    const path = "/users/money";
    const requestHeaders = {
      "Access-Control-Allow-Origin":
        "Authorization,Access-Control-Allow-Origin,Access-Control-Allow-Headers",
    };
    const queryStringParameters ={
      company: company,
      sub: uuid,
      startDate: startDate,
      endDate: endDate,
    };

    try {
      const restOperation = get({
        apiName: apiName, 
        path: path, 
        options: {
          headers: requestHeaders,
          queryParams: queryStringParameters
      }});
      const {body} = await restOperation.response;
      const json = await body.json();
      if (json == null || json?.balance === 0) {
        setUserTransactions(["User has no transactions yet"]);
      } else {
        setUserTransactions(json);
      }
      
    } catch (e) {
      setUserTransactions([
        "There was a problem fetching the transactions...",
      ]);
      console.log('There was an error fetching the transactions....');
      console.log(e);
      if (e instanceof ApiError) {
        if (e.response) {
          const { 
            statusCode, 
            body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
        }
      }
    }
  }

  async function getCognitoData() {
    const apiName = "users";
    const path = "/users/search";
    const requestHeaders = {
      "Access-Control-Allow-Origin":
        "Authorization, Access-Control-Allow-Origin,Access-Control-Allow-Headers",
    };
    const queryStringParameters = {
      searchEquality: "equals",
      searchString: uuid,
      searchItem: "sub",
    };

    try {
      const restOperation = get({
        apiName: apiName, 
        path: path, 
        options: {
          headers: requestHeaders,
          queryParams: queryStringParameters
        }
      });
      const { body } = await restOperation.response;
      const json = await body.json();
      if (
        json &&
        json.Users[0] &&
        json.Users[0].length !== 0
      ) {
        setCognitoData(json.Users[0]);
      } else {
        setCognitoData([
          "The data does not exist in Cognito. Please contact Justin ASAP",
        ]);
      }

    } catch (e) {
      console.log('There was an error setting the cognito data');
      console.log(e);
      alert('There was an error setting the cognito data. Please look at console (F12)');
      setCognitoData(["There was an error getting the Cognito record."]);
      if (e instanceof ApiError) {
        if (e.response) {
          const { 
            statusCode, 
            body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
        }
      }
    }
  
  }

  async function getDynamoDbData() {
    const apiName = "users";
    const path = "/users";
    const requestHeaders = {
      "Access-Control-Allow-Origin":
        "Authorization, Access-Control-Allow-Origin,Access-Control-Allow-Headers",
    };
    const queryStringParameters = {
      company: company,
      empID: uuid,
    };

    try {
      const restOperation = get({
        apiName: apiName, 
        path: path, 
        options: {
          headers: requestHeaders,
          queryParams: queryStringParameters
        }
      });
      const {body} = await restOperation.response;
      const json = await body.json();
      if (
        json &&
        json.length > 0 &&
        json[0].length !== 0
      ) {
        const retData = JSON.parse(json);
        setDynamoDB(retData);
      } else {
        setDynamoDB([
          "The data does not exist in DynamoDB. Please contact Justin ASAP",
        ]);
      }

    } catch (e) {
      console.log('There was an error setting the dynamoDB stuff. ');
      console.log(e);
      setDynamoDB(["There was an error getting the DynamoDB record."]);
      alert('error setting dynamo db. Please look at console (F12) and contact justin');
      if (e instanceof ApiError) {
        if (e.response) {
          const { 
            statusCode, 
            body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
        }
      }
    }

  }

  useEffect(() => {
    if (uuid == null || uuid.length < 1) {
      return;
    }
    if (company == null || company.length < 1) {
      return;
    }
    getDynamoDbData();
    getCognitoData();
    getUserTrips();
    getTransactions(0, 0);
  }, [uuid, company]);

  if (uuid == null || uuid.length < 1) {
    return (
      <div>
        <br />
        <br />
        There was an error and the UUID doesn't exist. You must navigate to this
        page by clicking on the row in the list on the View/Edit User page.{" "}
        <br />
        <br />
        <Link to="/listUser">Click Here to Go Back</Link>
      </div>
    );
  }
  return (
    <div className="container">
      <Sidebar />
      <main>
        <br />
        {dynamoDB && dynamoDB.length > 0 && (
          <div>
            <DynamoDbTable props={dynamoDB} />
          </div>
        )}
        <br />
        <div>
          {cognitoData && (
            <div>
              <CognitoTable props={cognitoData} />
            </div>
          )}
        </div>
        <br />
        <div>
          {userTransactions && (
            <div>
              <TransactionsTable
                props={{
                  transactions: userTransactions,
                  company: company,
                  uuid: uuid,
                  dynamo: dynamoDB,
                }}
              />
            </div>
          )}
        </div>
        <h1>
          <center>Trips</center>
        </h1>
        <div>
          {userTrips &&
            userTrips.map(function (trip) {
              return (
                <TripTable
                  props={trip}
                  key={trip?._lastChangedAt ?? "NoTripData"}
                />
              );
            })}
        </div>
        <br />
        <br />
        <br />
      </main>
    </div>
  );
};

export default UserDetails;
