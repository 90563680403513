import { getCurrentUser } from 'aws-amplify/auth';

export async function isUserLoggedIn() {
    try {
        //if the user is not logged in it throws an error
        const { username, userId, signInDetails } = await getCurrentUser();
        return true;
    } catch(e) {
        return false;
    }
}