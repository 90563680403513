import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from 'aws-amplify/auth';
import config from "./config";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

// Amplify.configure({
//   Auth: {
//     userPoolWebClientId: config.cognito.APP_CLIENT_ID,
//     identityPoolId: config.cognito.IDENTITY_POOL_ID,
//     region: config.cognito.REGION,
//     Cognito: {
//       mandatorySignIn: true,
//       userPoolId: config.cognito.USER_POOL_ID,
//       userPoolClientId: config.cognito.APP_CLIENT_ID,
      
//     }
//   },
//   API: {
//     REST: {
//       headers: async () => {
//         return {Authorization: `${(await fetchAuthSession()).tokens.idToken.toString()}`};
//       },
//       'users': {
//           name: "users",
//           endpoint: config.apiGateway.URL,
//           headers: async () => {
//             return {Authorization: `${(await fetchAuthSession()).tokens.idToken.toString()}`};
//           },
//       },
//     },
// },
// });
Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolClientId: config.cognito.APP_CLIENT_ID,
      allowGuestAccess: false, // This is the v6 equivalent of `mandatorySignIn: true`
    }
  },
  API: {
    REST: {
      'users': {
          name: "users",
          endpoint: config.apiGateway.URL,
      },
    },
  },
}, {
  API: {
    REST: {
      headers: async () => {
        return {Authorization: `${(await fetchAuthSession()).tokens.idToken.toString()}`};
      },
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <App />
        </LocalizationProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
