import React from "react";
import Button from "@mui/material/Button";
import "../../styles/main.scss";
import { useNavigate } from "react-router-dom";

const DynamoDbTable = (props) => {
  //const data = props.props[0];
  const data = props.props[0];
  const navigate = useNavigate();

  function goToEditPage() {
    navigate("/editUserDetails", { state: { userDetails: data } });
  };

  return (
    <form className="dynamo-form">
      <table className="styled-table">
        <thead>
          <tr>
            <td colSpan="2">
              <b>
                <u>Addventure DB Information</u>
              </b>
            </td>
            <td>
            <center>
              <Button variant="contained" color="primary" onClick={goToEditPage}>
                Edit
              </Button>
            </center>
              
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Name: </b>
              {data.name}
            </td>
            <td colSpan="2">
              <b>Company: </b>
              {data.pk}
            </td>
          </tr>
          <tr>
            <td rowSpan="3">
              {data.address1 != null ? data.address1 : "No Address 1"}
              <br />
              {data.address2}
              <br />
              {data.city}, {data.state} {data.zip}
            </td>
            <td>
              <b>ID: </b>
              {data.addventureId}
            </td>
            <td>
              <b>EMP Code: </b>
              {data.empCode}
            </td>
          </tr>
          <tr>
            <td>
              <b>Gender: </b>
              {data.gender}
            </td>
            <td>
              <b>Birthdate: </b>
              {data.birthDate}
            </td>
          </tr>
          <tr>
            <td>
              <b>Balance: </b>${data.accountBalance}
            </td>
            <td>
              <b>EE Contribution: </b>${data.ee_contribution}</td>
          </tr>
          <tr>
            <td>
              <b>Department: </b>
              {data.department}
            </td>
            <td>
              <b>Division: </b>
              {data.division}
            </td>
            <td>
              <b>Location: </b>
              {data.location}
            </td>
          </tr>
          <tr>
            <td>
              <b>Prime EE ID: </b>
              {data.primeEeId}
            </td>
            <td>
              <b>Relationship: </b>
              {data.relationship}
            </td>
            <td>
              <b>Active? </b>
              {data.activeStatus}
            </td>
          </tr>
          <tr>
            <td>
              <b>EE No: </b>
              {data.eeNo}
            </td>
            <td>
              <b>ER No:</b>
              {data.erNo}
            </td>
            <td>
              <b>Hire Date: </b>
              {data.hireDate}
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default DynamoDbTable;
