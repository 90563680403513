import React from "react";
import { useForm } from "react-hook-form";
import { post, ApiError } from 'aws-amplify/api';

import "../styles/form.scss";
import "../styles/main.scss";
import Sidebar from "../components/sidebar";
import TextInputForm from "../components/text_input_form";

async function submitHandler(values) {
  const apiName = "users";
  const path = "/portalusers";
  const requestBody = {
    email: values.email,
    phone: "+1" + values.phone,
  };
  const requestHeaders = {
    "Access-Control-Allow-Origin":
      "Authorization, Access-Control-Allow-Origin,Access-Control-Allow-Headers",
  };

  try {
    const restOperation = post({
      apiName: apiName, 
      path: path, 
      options: {
        headers: requestHeaders,
        body: requestBody,
      }
    });

    await restOperation.response;
    alert('We added the user');
  } catch (e) {
    alert('We DID NOT add the user and hit an error. Please see console (F12).');
    console.log('The error is below');
    console.log(e);
    if (e instanceof ApiError) {
      if (e.response) {
          const { 
          statusCode, 
          body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
      }
    }
  }
}

const AddAdminUser = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div className="container">
      <Sidebar />
      <main>
        <h1>
          <center>Add a User</center>
        </h1>
        <hr />
        <form onSubmit={handleSubmit(submitHandler)}>
          <TextInputForm
            fieldName="email"
            label="Email"
            id="email"
            register={register}
            error={errors.email}
          />
          <TextInputForm
            fieldName="phone"
            label="Phone"
            id="phone"
            register={register}
            error={errors.phone}
            pattern="[0-9]+" //must be all numbers - nothing else...
            minLength={10}
            maxLength={10}
          />

          <input type="submit" />
          <input type="reset" />
        </form>
      </main>
    </div>
  );
};

export default AddAdminUser;
