import { Button } from '@mui/material';
import React, { useState } from 'react';
import { post, ApiError } from 'aws-amplify/api';

import '../../styles/main.scss';
import TextInputModal from '../text_input_modal';
import ConfirmModal from '../confirm_modal';

const CognitoTable = (props) => {
    const data = props.props;
    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalText, setModalText] = useState("");
    const [labelText, setLabelText] = useState("");
    const [cogAttribute, setCogAttribute] = useState("");
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmReAdd, setConfirmReAdd] = useState(false);
    
    function changeAttribute(attrib, name) {
        setModalTitle('Changing ' + name);
        setModalText('You are changing the ' + name + ' of the user. This should only be done if they are having troubles logging in and the wrong one was put in the system.');
        setLabelText(name);
        setCogAttribute(attrib);
        setOpen(true);
    }

    function doNothing() {
        ;
    }

    async function resetTempPassword(attributes) {
        console.log('resending the temp password...');
        console.log('attributes are: ', attributes);
        const apiName = 'users';
        const path = '/users/resetcognito';
        const body = {
            last_name: getAttributeMatchingString('family_name', data.Attributes),
            first_name: getAttributeMatchingString('given_name', data.Attributes),
            addventureId: getAttributeMatchingString('custom:addventureId', data.Attributes),
            email: getAttributeMatchingString('email', data.Attributes),
            phone: getAttributeMatchingString('phone_number', data.Attributes),
            company: getAttributeMatchingString('custom:company', data.Attributes)
        };
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
        };
        try {
            const restOperation = post({
                apiName: apiName, 
                path: path, 
                options: {
                    headers: headers,
                    body: body,
                } 
            });
            //if it isn't a 200 response it throws an exception so we can safely assume it works
            const { postResponse } = await restOperation.response;
            console.log('worked like a champ!!!!');
            console.log(postResponse);
            setConfirmReAdd(true);
            
        } catch (e) {
            alert('There was an error. Please look at console (F12) and contact justin');
            console.log('There was an error! error is below');
            console.log(e)
            if (e instanceof ApiError) {
                if (e.response) {
                    const { 
                    statusCode, 
                    body 
                    } = e.response;
                    console.error(`Received ${statusCode} error response with payload: ${body}`);
                }
            }
        }
    }

    //Cognito expires the temp passwords after only 7 days!
    function isTempPasswordExpired(d) {
        const DAYS_TO_EXPIRE = 7;
        const dateNow = new Date();
        const dateToCheck = new Date(d)
        const expiredDate = dateNow.setDate(dateNow.getDate() - DAYS_TO_EXPIRE);
        if(dateToCheck > expiredDate) {
            return false;
        }
        return true;
    }

    async function confirmChangeAttribute(s) {
        if(s == null || s.length < 2) {
            alert('It looks like nothing was entered? Aborting and not adding...');
            return;
        }
        if(cogAttribute === 'phone_number' && s.length !== 10 && isNaN(s)) {
            alert('Only numbers allowed. Must be 10 digits! Aborting...');
            return;
        }
        if(cogAttribute === 'phone_number') {
            s = '+1' + s;
        }
        const apiName = 'users';
        const path = '/cogupdate';
        const requestBody = {
            username: getAttributeMatchingString('sub', data.Attributes),
            attributeName: cogAttribute,
            attributeValue: s
        };
        const requestHeaders = {
            "Access-Control-Allow-Origin" : "Authorization, Access-Control-Allow-Origin,Access-Control-Allow-Headers"
        };

        try {
            const restOperation = post({
                apiName: apiName, 
                path: path, 
                options: {
                    headers: requestHeaders, 
                    body: requestBody
                }
            });
            await restOperation.response;
            //throws exception if not status code 2xx.
            setConfirmOpen(true);

        } catch (e) {
            console.log('There was an error. ');
            alert('Error. Please check console (F12) and contact justing');
            console.log(e);
            if (e instanceof ApiError) {
                if (e.response) {
                    const { 
                    statusCode, 
                    body 
                    } = e.response;
                    console.error(`Received ${statusCode} error response with payload: ${body}`);
                }
            }
        }
    }

   

    function getAttributeMatchingString(s, list) {
        if(list == null || list.length === 0) {
            return 'Unknown';
        }
        var pair = list.filter(item => {
            return item.Name === s;
        });
        return pair[0].Value;
    }

    //below should never happen - you have to have a cognito entry to get a uuid. You have
    // to have a uuid to get entered into dynamoDB. You have to be in dynamoDB to be on previous
    // screen.
    if(data[0] === 'The data does not exist in Cognito. Please contact Justin ASAP') {
        return (
            <div>
                <h3><center>{data}</center></h3>
            </div>
        );
    }
    return (
        <div>
        <table className="styled-table">
            <thead >
                <tr>
                    <td colSpan='2'>
                        <b><u>Cognito Information</u></b>
                    </td>
                </tr>
            </thead>
            <tbody>
            {(data.UserStatus === 'FORCE_CHANGE_PASSWORD' && isTempPasswordExpired(data.UserCreateDate)) &&
                    <tr>
                        <td>
                            <b><center>Temp Password Expired!!!!</center></b>
                        </td>
                        <td>
                        <Button variant="outlined" color="primary" 
                                disableElevation fullWidth
                                style={{
                                    borderRadius: 15,
                                    backgroundColor: "#f36c2166",
                                    fontSize: "18px"
                                }}
                                onClick={() => resetTempPassword(data.Attributes)}
                            >
                                Reset Temp Password
                            </Button>
                        </td>
                    </tr>
                }
                <tr>
                    <td>
                        <b>Name: </b>{getAttributeMatchingString('given_name', data.Attributes)}&nbsp;
                        {getAttributeMatchingString('family_name', data.Attributes)}
                    </td>
                    <td>
                        <b>Company: </b>{getAttributeMatchingString('custom:company', data.Attributes)}
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Email: </b>{getAttributeMatchingString('email', data.Attributes)}
                    </td>
                    <td>
                        <b>Email Verified? </b>{getAttributeMatchingString('email_verified', data.Attributes)}
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Phone: </b>{getAttributeMatchingString('phone_number', data.Attributes)}
                    </td>
                    <td>
                        <b>Phone Verified? </b>{getAttributeMatchingString('phone_number_verified', data.Attributes)}
                    </td>
                </tr>
                <tr>
                    <td colSpan='2'>
                        <b>ID: </b>{getAttributeMatchingString('sub', data.Attributes)}
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Enabled? </b>{data.Enabled?.toString()}
                    </td>
                    <td>
                        <b>Status: </b>{data.UserStatus}
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Created: </b>{data.UserCreateDate}
                    </td>
                    <td>
                        <b>Last Modified: </b>{data.UserLastModifiedDate}
                    </td>
                </tr>
                {data.UserStatus === 'FORCE_CHANGE_PASSWORD' &&
                    <tr>
                        <td>
                            <Button variant="outlined" color="primary" 
                                disableElevation fullWidth
                                style={{
                                    borderRadius: 15,
                                    backgroundColor: "#f36c2166",
                                    fontSize: "18px"
                                }}
                                onClick={() => changeAttribute('phone_number', 'phone')}
                            >
                                Change Phone
                            </Button>
                        </td>
                        <td>
                        <Button variant="outlined" color="primary" 
                                disableElevation fullWidth
                                style={{
                                    borderRadius: 15,
                                    backgroundColor: "#f36c2166",
                                    fontSize: "18px"
                                }}
                                onClick={() => changeAttribute('email', 'email')}
                            >
                                Change Email
                            </Button>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
            <TextInputModal 
                title={modalTitle}
                content={modalText}
                open={open}
                setOpen={setOpen}
                onConfirm={confirmChangeAttribute}
                labelText={labelText}
            />
            <ConfirmModal
                title="Value is Updated"
                content="The value has been updated. However, because we use the 'Eventually Consistent' model, it means it will probably be there by the time this modal is closed, just not instantaneously. Please refresh this page if you need to verify it being there."
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={doNothing}
            />
            <ConfirmModal
                title="User re-added"
                content="The user has been re-added, which means they should have a new email now within 5 minutes or so. This temp password must be used within 7 days from right now or we will be back in this same spot and they need to be added yet again."
                open={confirmReAdd}
                setOpen={setConfirmReAdd}
                onConfirm={doNothing}
            />
        </div>
        
    );
}

export default CognitoTable;