import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/documentation.scss";
import Button from "@mui/material/Button";

const Documentation = (props) => {
  const navigate = useNavigate();
  return (
    <main className="main">
      <br />
      <Button
        onClick={() => {
          navigate(-1);
        }}
        color="primary"
      >
        Go Back
      </Button>
      <center>
        <h1>Documentation - Admin Portal</h1>
      </center>
      <hr width={400} color="#f36c21" />
      <center>
        <h3>First Things First</h3>
      </center>
      We are tied to WEX and must be set up there. You should first create a new
      company in WEX using their Portal. After that company is set up, then you
      need to come to this Admin Portal and set up a Company (Clients -&gt; Add
      Client) in the Admin Portal. It is very important that the items that are
      marked to match WEX must match WEX.
      <ul>
        <li>
          <b>Employee Code</b> - this is the 5 or 6 digit company code that must
          match. It also must be unique. In other words, if you onboard Delta
          the airplane, you cannot re-use Delta for a Delta Dental company. This
          code is never exposed to the end user in any way and just something
          that ties WEX and Addventure together. For a reference, in our testing
          we used BRUCEW
        </li>
        <li>
          <b>Payroll Frequency</b> - this is the frequency the payrolls will be
          deposited. This becomes super-important when we onboard a new user
          from the Enrollment Portal. If this does not match a valid value set
          up in WEX (and match{" "}
          <b>
            <u>exactly</u>
          </b>
          ) then the user will get onboarded on our side and be able to use the
          app, but the file import will fail on the user import in WEX and no
          monet file will be able to be uploaded.
        </li>
        <li>
          <b>Plan Name</b> - this must match{" "}
          <b>
            <u>exactly</u>
          </b>{" "}
          to the plan name set up in WEX. If this is not set up correctly, the
          same thing will happen as above with the Payroll Frequency and the
          user will be able to load the app on our side but the user load will
          fail.
        </li>
      </ul>
      After the company is set up on our side, you are able to upload the user
      file to onboard users. to do this go to Users -&gt; Upload New User File
      in the Admin Portal.
      <hr width={400} color="#f36c21" />
      <center>
        <h3>Uploading New Users</h3>
      </center>
      <p>
        To upload new users, you go to Users -&gt; Upload New User File in the
        Admin Portal. The file must be a csv file and match a very specific
        format. A sample file can be seen <a href="/sample_upload.csv">here</a>.
        There are two required fields, while everything else is mandatory.
        <br />
        Required Fields in file:
      </p>
      <ul>
        <li>ee_id - this must be unique for each user in each company.</li>
        <li>email - this is where the first Addventure email will be sent.</li>
      </ul>
      <p>
        Everything else in the file is not required. If it is not supplied and
        is important for us to have, the user can submit it when filling out the
        Enrollment Portal. If it is supplied here and important, the user will
        have a chance to edit it when filling out the enrollment portal. For
        instance, if their cell phone was entered incorrectly, they can change
        that when they fill out the enrollment portal. Once the file passes a
        few checks and is deemed good, the email is sent to the user and they
        can access the enrollment portal with the email address they received
        the email at. Note: They can even change their email address at the
        enrollment portal and it will be acceptable and OK. The header row{" "}
        <b>must</b> be present and should match to what is in the sample file.
      </p>
      <br />
      <hr width={400} color="#f36c21" />
      <center>
        <h3>Onboarding Flow</h3>
      </center>
      The onboarding flow is somewhat complex, so it should be documented here.
      These are the steps, in chronological order, of what happens behind the
      scenes and what happens with user intervention.
      <ol>
        <li>Admin attempts to upload user file.</li>
        <li>
          If successful and good format, a temporary row is entered into
          DynamoDB for each new user.
        </li>
        <li>
          An email is immediately set out once the temp row is entered into
          DynamoDB.
        </li>
        <li>
          The user gets the email and when they are ready, will go to the
          Enrollment Portal and enroll.
        </li>
        <li>
          After verifying the information is correct and submitting the
          Enrollment Portal form, a lot of things happen simultaneously:
        </li>
        <ul>
          <li>User is created in Cognito so they can use the app</li>
          <li>
            Each new Cognito user added triggers an email to be sent to the
            person with their username and password so they can log into the
            app. The username will be their email. The password will be a
            combination of their last name and their Addventure ID, separated by
            a # symbol. If that is not 8 characters, the first name will be
            added to the mix.
          </li>
          <li>The real Dynamo DB row is added</li>
          <li>
            The temporary dynamoDB row is deleted so they can no longer fill out
            the form a second time.
          </li>
          <li>
            A new user file is sent to WEX via their sftp process and they will
            be onboarded within a few minutes afterwards.
          </li>
          <li>
            The file that was sent to WEX via sftp is also stored internally in
            S3 for access later in case any questions/concerns arise.
          </li>
        </ul>
        <li>
          The new user then checks their email (this email is sent to the same
          email as their username - remember it could be changed in the
          Enrollment Portal from what we uploaded in the file) and has the login
          information. They are also prompted to download the app and can now
          log in.
        </li>
        <li>
          The first time a user logs in, they will be forced to change their
          password. At this point, we have no idea what their password is
          anymore and can never find out their password. If they wish to change
          their password, they need to do it in the app.
        </li>
        <li>User creates trips, saves money, enjoys life</li>
        <li>
          Admin processes payments via upload deposits section in the Admin
          Portal.
        </li>
        <li>Steps 8 and 9 are repeated forever.</li>
      </ol>
      <br />
      <hr width={400} color="#f36c21" />
      <center>
        <h3>Logging In Problems</h3>
      </center>
      The user may experience an issue logging in. This can probably be broken
      down into only a few categories:
      <ul>
        <li>
          <b>User Forgot Username/Password</b> - They will have to reset this
          themselves if they have already logged in before successfully. We
          could manually change it for v2 if we wanted to, but for now we do not
          have that option. So they must do it themselves.
        </li>
        <li>
          <b>User had wrong email on Enrollment Portal</b> - If the user put the
          wrong email on the Enrollment Portal and cannot access it, they do not
          have a way to view their temporary password. The email may be in their
          spam/junk folder. Or they may just not have access to that email
          anymore. Since the user has not logged in before, you will see a
          section in the Admin Portal where you can change their email. This
          will
          <b>not</b> resend the enrollment email with their username/password
          enclosed, but you can get their temp password from their last name
          (first letter is capitalized, rest all lowercase), then a #, then
          their Addventure ID. (Under Addventure DB information on user page is
          the ID).{" "}
        </li>
        <li>
          <b>User entered wrong phone number in Enrollment Portal</b> - Maybe
          the user entered their office phone or mistyped the number. If this is
          the case, they will never get the 2FA code sent to them to finish
          logging in. You can go to their user page and enter the correct phone
          number and at this point they should get the code sent to their newly
          entered number.
        </li>
        <li>
          <b>User has waited too long to act on Cognito-triggered email</b> -
          The user only has 14 days to sign in once they are entered in Cognito.
          After that, we must reset their password. This is still a work in
          progress, but something we should be on the lookout for. While rare,
          it could happen.
        </li>
      </ul>
      <br />
      <hr width={400} color="#f36c21" />
      <center>
        <h3>Uploading Deposits</h3>
      </center>
      <p>
        You have to periodically upload deposits so the user has money in their
        accounts. You should <u>always</u> do this through the Upload Deposits
        page in the Admin Portal. A sample file can be found at{" "}
        <a href="/sample_contribution.csv">this</a> link. The header row must be
        present and all fields must exist. The max length of the file is 50 rows
        (this is a restraint of AWS and how many concurrent records can be
        upgraded simultaneously). It is important to remember that a user{" "}
        <b>MUST</b> not have more than 2 records per file. And one record should
        be ER and one EE for the second column. If these are both the same, WEX
        will reject the latter one. If you wanted to verify the deposit was
        successful on WEX side, you can log into their portal, view the gray
        Data tab near the top, then click on View Import Queue on the far right
        side. However, the file may take up to 5 minutes to show up there and be
        processed. This is also where you would check periodically in case you
        were curious about user creation after the user finishes the Enrollment
        Portal.
      </p>
      <p>
        The money that is displayed in the app is from Addventure's DynamoDB
        side and not WEX's side. Because we are restricted by their sftp process
        to reconcile a user's money, we cannot update it in real time when the
        user uses their debit card. We have a nightly cron job that runs and
        tries to reconcile the records, but if something is not correct an email
        will be fired off and you will have to go in and manually edit the
        transactions by adding a new one. For instance, if a user uses their
        debit card to buy a plane ticket through Delta, you will get an email
        the next morning saying their accounts are not in sync. You can log into
        the Admin Portal, go to View/Edit User and find the user then click on
        their row in the table. This will take you to another page and the third
        table is the transactions table. Hit the blue Add button and at this
        point you will be taken to a new screen to enter a manual transaction.
        This page should only be used if the deposits are out of sync and it is
        important to remember this will{" "}
        <b>
          <u>only</u>
        </b>{" "}
        update the Addventure DynamoDB side and not the WEX side. So you would
        most likely put a negative amount in here for the transaction as the
        user used their card which was a withdrawal.
      </p>
      <br />
      <br />
      <br />
      <br />
    </main>
  );
};

export default Documentation;
