import { UseFormRegister, FieldError } from "react-hook-form";
import "../styles/form.scss";

const TextInputForm = ({
  label,
  fieldName,
  placeholder,
  textArea,
  inputType = "text",
  register,
  error,
  onInput,
  pattern,
  minLength,
  maxLength,
}) => {
  const options = { required: `${label} is required.` };
  return (
    <div className="form">
      <label className="label">
        <span className="label">{label}</span>
      </label>
      {textArea ? (
        <textarea
          rows={6}
          placeholder={placeholder}
          {...register(fieldName, options)}
          className="textArea"
        ></textarea>
      ) : (
        <input
          type={inputType}
          placeholder={placeholder}
          {...register(fieldName, options)}
          className="textArea"
          onInput={onInput}
          pattern={pattern}
          minLength={minLength}
          maxLength={maxLength}
        />
      )}
      {error && (
        <span className="text-sm font-semibold text-secondary pt-3 pl-2">
          {error?.message}
        </span>
      )}
    </div>
  );
};

export default TextInputForm;
