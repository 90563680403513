import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
//import 'react-pro-sidebar/dist/css/styles.css';
import "../styles/sidebar.scss";
import { FiUser, FiLogOut } from "react-icons/fi";
import { AiOutlineHome } from "react-icons/ai";
import { IoPeopleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { signOut } from 'aws-amplify/auth'

function Sidebar() {
  return (
    <ProSidebar>
      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<AiOutlineHome />}>
            Home
            <Link to="/" />
          </MenuItem>
          <SubMenu title="Users" icon={<FiUser />}>
            <MenuItem>
              Upload New User File
              <Link to="/newUserFile" />
            </MenuItem>
            <MenuItem>
              View/Edit User
              <Link to="/listUser" />
            </MenuItem>
            <MenuItem>
              Not Yet Enrolled
              <Link to="/notenrolled" />
            </MenuItem>
            <MenuItem>
              Upload Deposits
              <Link to="/addMoney" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="Clients" icon={<IoPeopleOutline />}>
            <MenuItem>
              View Companies
              <Link to="/clients" />
            </MenuItem>
            <MenuItem>
              Add Client
              <Link to="/addClient" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="Admin Users" icon={<IoPeopleOutline />}>
            <MenuItem>
              Add Admin User
              <Link to="/addAdminUser" />
            </MenuItem>
            <MenuItem>
              List Admin Users
              <Link to="/listAdminUsers" />
            </MenuItem>
          </SubMenu>
          <MenuItem icon={<AiOutlineHome />}>
            Documentation
            <Link to="/docs" />
          </MenuItem>
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <Menu iconShape="circle">
          <MenuItem icon={<FiLogOut />} onClick={()=> {
            signOut();
            window.open('/', '_self');
            }}>Logout</MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
}

export default Sidebar;
