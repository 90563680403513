import React from 'react';
import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux'

import '../styles/main.scss';
import Sidebar from '../components/sidebar';
import { fetchCompaniesFromAWS } from '../store/companies';
import '../styles/home.scss';

const Home = () => {
    const dispatch = useDispatch();
    const comp = useSelector((state) => state.companies);

    const data = comp.companies;

    useEffect(() => {
        dispatch(fetchCompaniesFromAWS(data));
    }, [dispatch, data]);

    return (
        <div className="container">
            <Sidebar />
            <main>
            <h1><center>Home</center></h1>
            <h3 className="padLeftRight">Welcome to the Admin Portal! Here you can view, add, edit numerous things concerning the 
                Addventure mobile app. Please be careful as this is live and fully hooked up. Some actions
                will trigger a text or an email.</h3>
            <br />
            <div className="separatedRow">
                <div className="panel">
                    <div className="panelTitle">App Users</div>
                    <div className="panelText">Find, edit, and add users to the app. <br /><br /><br /><br /></div>

                </div>
                <div className="panel">
                    <div className="panelTitle">Companies</div>
                    <div className="panelText">Add new companies to Addventure and find contact information for companies. </div>
                </div>
            </div>
            <br />
            <br />
            <div className="separatedRow">
                <div className="panel">
                    <div className="panelTitle">Admin Portal Users</div>
                    <div className="panelText">Add or delete users who can access this Admin Portal. </div>

                </div>
                <div className="panel">
                    <div className="panelTitle">Documentation</div>
                    <div className="panelText">View the corresponding Addventure technical documentation. </div>
                </div>
            </div>
            
            
            </main>
        </div>
    );
}

export default Home;