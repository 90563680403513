import React from "react";
import { useSelector } from "react-redux";

import Sidebar from "../components/sidebar";
import "../styles/main.scss";

const ListCompanies = () => {
  const comp = useSelector((state) => state.companies);

  return (
    <div className="container">
      <Sidebar />
      <main>
        <div>
          <table className="styled-table">
            <thead>
              <tr key="header">
                <th>Company</th>
                <th>Contact</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Emp Code</th>
                <th>Payroll Frequency</th>
                <th>Plan Name</th>
                <th>Wex Money Plan</th>
              </tr>
            </thead>
            <tbody>
              {comp.companies &&
                comp.companies.map((company) => (
                  <tr key={company.sk}>
                    <td>{company.sk}</td>
                    <td>{company.contact_name}</td>
                    <td>{company.contact_phone}</td>
                    <td>{company.contact_email}</td>
                    <td>{company.emp_code}</td>
                    <td>{company.payFreq}</td>
                    <td>{company.planName}</td>
                    <td>{company.wexMoneyPlanName}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <br />
          <br />
          <center>
            <h2>NOTES</h2>
          </center>
          <ul>
            <li>
              The <b>Emp Code</b> must match what is set up in WEX.
            </li>
            <li>
              The <b>Payroll Frequency</b> must match what is set up in WEX.
            </li>
            <li>
              The <b>Plan Name</b> must match what is set up in WEX.
            </li>
            <li>
              Changing the <b>Plan Name</b> will change what we send to WEX on
              the pay/deposit uploads for next upload.
            </li>
          </ul>
        </div>
      </main>
    </div>
  );
};

export default ListCompanies;
