import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { post, ApiError } from 'aws-amplify/api';

import "../styles/main.scss";
import Sidebar from "../components/sidebar";
import DragDropFile from "../components/drag_drop_file";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";

function checkHeader(columns) {
  if (columns.length !== 18) {
    return `There are not 18 columns. This file is not valid because it has ${columns.length}`;
  }
  //skip 1 - er_no. We don't use it internally at all.
  if (columns[1] !== "ee_id") {
    return `Second column must be 'ee_id. Your header in second column is: ${columns[1]}`;
  }
  if (columns[2] !== "last_name") {
    return `Third column must be 'last_name'. Your header in third column is: ${columns[2]}`;
  }
  //skip 3 - middle name
  if (columns[4] !== "first_name") {
    return `Fifth column must be 'first_name'. Your header in fifth column is: ${columns[4]}`;
  }
  if (columns[5] !== "address_1") {
    return `Six column must be 'address_1'. Your header in sixth column is: ${columns[5]}`;
  }
  if (columns[6] !== "address_2") {
    return `Seventh column must be 'address_2'. Your header in seventh column is: ${columns[6]}`;
  }
  if (columns[7] !== "city") {
    return `Eigth column must be 'city'. Your header in eigth column is: ${columns[7]}`;
  }
  if (columns[8] !== "state") {
    return `Ninth column must be 'state'. Your header in ninth column is: ${columns[8]}`;
  }
  if (columns[9] !== "zip_code") {
    return `Tenth column must be 'zip_code'. Your header in tenth column is: ${columns[9]}`;
  }
  //skip 10 - hire date
  if (columns[11] !== "birth_date") {
    return `Twelth column must be 'birth_date'. Your header in twelth column is: ${columns[11]}`;
  }
  //skip 12 - gender
  if (columns[13] !== "email") {
    return `Fourteenth column must be 'email'. Your header in fourteenth column is: ${columns[13]}`;
  }
  if (columns[14] !== "phone") {
    return `Fifteenth column must be 'phone'. Your header in fifteenth column is: ${columns[14]}`;
  }
  //skip 15 - active status
  //skip 16 - term date
  if (columns[17] !== "ee_contribution") {
    return `Eighteenth column must be 'ee_contribution'. Your header in eighteenth column is: ${columns[17]}`;
  }
  return null;
}

function parseData(data) {
  const dummyArr = [];
  const eachLine = data?.toString().split("\r\n");
  eachLine?.forEach((line) => {
    const arr = line.split(",");
    dummyArr.push(arr);
  });
  dummyArr.shift(); //remove header row
  return dummyArr;
}

function checkFileBody(body) {
  if (body.length > 100) {
    return `The file is too large. Max data rows is 100 and you gave ${body.length} rows. Please fix`;
  }
  for (let i = 0; i < body.length; i++) {
    if (body[i].length !== 18) {
      return `The length of a row is not the required 18, but ${
        body[i].length
      } instead. Row: ${i + 1}.`;
    }
    //only thing we require is eeNo (column[1]) and email (column[13])
    if (body[i][1].length < 3) {
      return `The minimum length for an EEID is 3. You entered ${
        body[i][0].length
      } characters and (${body[i][1]}) on row ${i + 1}.`;
    }
    if (body[i][13].length < 5) {
      return `The 'email' is missing on row ${i + 1}.`;
    }
    if (!body[i][13].includes("@")) {
      return `The 'email' is not valid on row ${i + 1}.`;
    }

    if(body[i][17] !== null && isNaN(parseFloat(body[i][17]))) {
      return `The 'ee_contribution' is not a number on row ${i+1}. The value is: ${body[i][17]}` ;
    }
  }
  return null;
}

function convertBirthDate(oldDate) {
  if (oldDate === null || oldDate === undefined || oldDate.length < 2) {
    return null;
  }
  const arrParts = oldDate.split("/");
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (arrParts.length !== 3) {
    console.log("Date is not correct size - Does not have 3 parts");
    return null;
  }
  if (isNaN(arrParts[0]) || isNaN(arrParts[1]) || isNaN(arrParts[2])) {
    console.log("parst are not numbers. bailing");
    return null;
  }
  if (arrParts[2] < 1850) {
    return null;
  }

  return `${months[arrParts[0] - 1]} ${arrParts[1]}, ${arrParts[2]}`;
}

function translateRowToObject(row, emp_code) {
  const name = `${row[4]} ${row[2]}`;
  name.trim();
  const entry = {
    eeNo: row[1],
    name: name,
    address1: row[5],
    address2: row[6],
    city: row[7],
    state: row[8],
    zip: row[9],
    birthDate: convertBirthDate(row[11]),
    email: row[13],
    contact_phone: row[14],
    emp_code: emp_code,
    ee_contribution: row[17],
  };
  Object.keys(entry).forEach((key) => {
    if (entry[key] === null || entry[key].length === 0) {
      delete entry[key];
    }
  });
  return entry;
}

function parseBodyData(body, emp_code) {
  let arrBody = [];
  for (let i = 0; i < body.length; i++) {
    arrBody.push(translateRowToObject(body[i], emp_code));
  }
  return arrBody;
}

async function sendToBackend(body, emp_code, setText) {
  const bodyToSend = parseBodyData(body, emp_code);
  const apiName = "users";
  const path = "/users/bulkadd";
  const requestHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const requestBody = { data: bodyToSend };

  try {
    const restOperation = post({
      apiName: apiName, 
      path: path, 
      options: {
        headers: requestHeaders,
        body: requestBody
      }
    });

  await restOperation.response;
  //if here, it is a 2xx status code and we are good
  setText(`We loaded ${body.length} records and sent welcome emails.`);
  } catch (e) {
    alert('There was an error in sending the file to the backend. Please look at console (F12) and contact justin');
    console.log('The error is below');
    console.log(e);
    if (e instanceof ApiError) {
      if (e.response) {
          const { 
          statusCode, 
          body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
      }
    }
    setText(
      "There was an error. Please look at console log and notify Justin"
    );
  }
}

async function checkFile(data, columns, company) {
  const headerCheck = checkHeader(columns);
  if (headerCheck !== null) {
    return headerCheck;
  }
  const body = parseData(data);
  const bodyCheck = checkFileBody(body);
  if (bodyCheck !== null) {
    return bodyCheck;
  }
  return body;
}

const UploadNewUserFile = () => {
  const [upFiles, setUpFiles] = useState();
  //parsed data is below
  const [data, setData] = useState([]);
  const comp = useSelector((state) => state.companies);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [errorText, setErrorText] = useState("");
  const [loadingText, setLoadingText] = useState("");
  const [finishedText, setFinishedText] = useState("");

  const companyChanged = (val) => {
    if (val.target.value != null && val.target.value.length > 0) {
      setSelectedCompany(val.target.value);
    }
  };

  const Select = ({ label, id, children, ...rest }) => (
    <div>
      <label htmlFor={id}>{label}</label>
      <select id={id} {...rest} onChange={companyChanged}>
        {children}
      </select>
    </div>
  );

  useEffect(() => {
    if (upFiles === null || upFiles === undefined) {
      return;
    }
    if (upFiles.length > 1) {
      alert("You can only upload 1 file at a time. Please try again");
      return;
    }
    if (upFiles[0]?.name?.endsWith(".csv") !== true) {
      alert("You can only upload csv files");
      return;
    }
    if (
      selectedCompany == null ||
      selectedCompany === undefined ||
      selectedCompany.length < 1
    ) {
      alert("You must select a company before uploading a file.");
      return;
    }
    setErrorText("");
    setFinishedText("");
    setLoadingText("Loading file. Please do not navigate away...");
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      const columns = Object.keys(parsedData[0]);
      const isFileGood = await checkFile(
        reader.result,
        columns,
        selectedCompany
      );
      if (typeof isFileGood === "string") {
        setErrorText(isFileGood);
      }
      if (typeof isFileGood === "object") {
        const emp_code = comp.companies.find(
          (elt) => elt.sk === selectedCompany
        ).emp_code;
        setLoadingText(
          "File is good. Uploading. Do not leave this page please..."
        );
        await sendToBackend(isFileGood, emp_code, setFinishedText);
        setLoadingText("");
      }
      setData(reader.result);
    };
    reader.readAsText(upFiles[0]);
    setUpFiles(null); //reset for next upload - file must change name if not
  }, [upFiles]);

  return (
    <div className="container">
      <Sidebar />
      <main>
        <div className="topRow">
          <Select label="" id="company" name="company" required>
            <option value="">Select a company...</option>
            {comp.companies &&
              comp.companies.map((x, y) => <option key={x.sk}>{x.sk}</option>)}
          </Select>
        </div>
        <hr />
        <h1>
          <center>Drop New User File Below</center>
        </h1>
        <h4>
          <center>{selectedCompany}</center>
        </h4>
        {loadingText.length === 0 && <DragDropFile setUpFiles={setUpFiles} />}
        {loadingText && (
          <div>
            <center>{loadingText}</center>
          </div>
        )}
        <hr />
        {errorText && (
          <div>
            <br />
            <center>
              <b style={{ color: "#f36c21" }}>ERROR! </b>
              {errorText}
              <br />
              <br />
              <br />
              <Button
                variant="contained" 
                onClick={() => {
                  setErrorText("");
                  setLoadingText("");
                }}>
                Retry
              </Button>
            </center>
          </div>
        )}
        {finishedText && (
          <div>
            <br />
            <center>
              <b style={{ color: "#f36c21" }}>Finished! </b>
              {finishedText}
            </center>
          </div>
        )}
      </main>
    </div>
  );
};

export default UploadNewUserFile;
