import React, { useEffect, useState } from "react";
import { Route, Navigate } from "react-router-dom";
import { isUserLoggedIn } from "../shared_functions";
import { Loading } from "./loader";

export const PrivateRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await isUserLoggedIn();

      setIsAuthenticated(result);
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading === true) {
    return <Loading />;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
  /*
  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? (
          <Component {...props} />
        ) : loading ? (
          <div>LOADING...</div>
        ) : (
          <Navigate
            to="/login"
            //to={{
            //    pathname: "/login",
            //    state: { from: props.location },
            //}}
          />
        )
      }
    />
  );
  */
};
