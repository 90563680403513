import '../App.css';
import Footer from '../components/footer';
import HeaderNonAuth from '../components/header_non_auth';
import UnPwEntry from '../components/login/un_pw_entry';

const Login = () => {
    console.log('Logging in....');
    return (

        <div className='bgImage'>
            <HeaderNonAuth />
            <UnPwEntry />
            <Footer />
        </div>

    );
}

export default Login;