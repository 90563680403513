import { createSlice } from "@reduxjs/toolkit";
import { get, ApiError } from 'aws-amplify/api';

// Slice
const slice = createSlice({
  name: "companies",
  initialState: {
    companies: null,
    isLoading: false,
  },
  reducers: {
    updateCompanies: (state, action) => {
      state.companies = action.payload;
    },
    updateIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
export default slice.reducer;

// Actions
const { updateIsLoading, updateCompanies } = slice.actions;
export const fetchCompaniesFromAWS = (stored) => async (dispatch) => {
  if (stored != null) {
    return;
  }
  dispatch(updateIsLoading(true));
  try {
    const apiName = "users";
    const path = "/companies";
    const restOperation = get({
      apiName: apiName, 
      path: path, 
      options: {
        headers: {
          'Access-Control-Allow-Origin':
            "Authorization, Access-Control-Allow-Origin, Access-Control-Allow-Headers"
          }, 
          body: {},
        }
      });
    const { body } = await restOperation.response;
    const response = await body.json();
    if (response != null) {
      const data = JSON.parse(response);
      dispatch(updateCompanies(data));
    }
  } catch (e) {
    alert('There was an error fetching the companies. Please look at console (F12) and speak with justin');
    console.log('The error is below');
    console.log(e);
    if (e instanceof ApiError) {
      if (e.response) {
          const { 
          statusCode, 
          body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
      }
    }
    return console.error(e.message);
  } finally {
    dispatch(updateIsLoading(false));
  }
};

export const forceFetchCompaniesFromAWS = () => async (dispatch) => {
  console.log("Force fetching companies");
  dispatch(updateIsLoading(true));
  try {
    const apiName = "users";
    const path = "/companies";
    const requestHeaders = {
      "Access-Control-Allow-Origin":
        "Authorization, Access-Control-Allow-Origin, Access-Control-Allow-Headers",
    };
    
    const restOperation = get({
      apiName: apiName, 
      path: path, 
      options: {
        headers: requestHeaders
      }
    });
    const { body } = await restOperation.response;
    const json = await body.json();
    if(json != null) {
      const pureJson = JSON.parse(json);
      dispatch(updateCompanies(pureJson));
    }
  } catch (e) {
    alert('There was an error forceFetching the companies. Please look at console (F12) and talk to justin');
    console.log('The error is below');
    console.log(e);
    if (e instanceof ApiError) {
      if (e.response) {
          const { 
          statusCode, 
          body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
      }
    }
    return console.error(e.message);
  } finally {
    dispatch(updateIsLoading(false));
  }
};
