import { Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import AddUser from "./pages/add_user";
import { PrivateRoute } from "./components/private_route";
import ListUsers from "./pages/list_users";
import Home from "./pages/home";
import ListCompanies from "./pages/list_companies";
import AddClient from "./pages/add_client";
import ListAdminUsers from "./pages/list_admin_users";
import AddAdminUser from "./pages/add_admin_user";
import UploadUserFile from "./pages/upload_user_file";
import UserDetails from "./pages/user_details";
import UploadPayFile from "./pages/upload_pay";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AddMoneyTransaction from "./pages/add_money";
import Documentation from "./pages/documentation";
import NotEnrolled from "./pages/not_enrolled";
import EditUserDetails from "./pages/edit_user_details";

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <main>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            exact
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/addUser"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/newUserFile"
            element={
              <PrivateRoute>
                <UploadUserFile />
              </PrivateRoute>
            }
          />
          <Route 
            path="/notenrolled"
            element={
              <PrivateRoute>
                <NotEnrolled />
              </PrivateRoute>
            }
          />
          <Route
            path="/listUser"
            element={
              <PrivateRoute>
                <ListUsers />
              </PrivateRoute>
            }
          />
          <Route
            path="/clients"
            element={
              <PrivateRoute>
                <ListCompanies />
              </PrivateRoute>
            }
          />
          <Route
            path="/addClient"
            element={
              <PrivateRoute>
                <AddClient />
              </PrivateRoute>
            }
          />
          <Route
            path="/listAdminUsers"
            element={
              <PrivateRoute>
                <ListAdminUsers />
              </PrivateRoute>
            }
          />
          <Route
            path="/addAdminUser"
            element={
              <PrivateRoute>
                <AddAdminUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/uploadUserFile"
            element={
              <PrivateRoute>
                <UploadUserFile />
              </PrivateRoute>
            }
          />
          <Route
            path="/userDetails"
            element={
              <PrivateRoute>
                <UserDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/addMoney"
            element={
              <PrivateRoute>
                <UploadPayFile />
              </PrivateRoute>
            }
          />
          <Route
            path="/addTransaction"
            element={
              <PrivateRoute>
                <AddMoneyTransaction />
              </PrivateRoute>
            }
          />
          <Route
            path="/editUserDetails"
            element={
              <PrivateRoute>
                <EditUserDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/docs"
            element={
              <PrivateRoute>
                <Documentation />
              </PrivateRoute>
            }
          />
        </Routes>
      </main>
    </ThemeProvider>
  );
}

export default App;
