import React,  { useState, useEffect }  from "react";
import Button from "@mui/material/Button";
import "../../styles/main.scss";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import '../../styles/main.scss';
import { CircularProgress } from "@mui/material";
import { get, ApiError } from 'aws-amplify/api';

const TransactionsTable = (props) => {
  const data = props.props.transactions;
  const company = props.props.company;
  const uuid = props.props.uuid;
  const dynamo = props.props.dynamo;
  const navigate = useNavigate();

  const money = data?.transactions?.data;
  money?.sort((a,b) => b.sk - a.sk);
  const [moneyTrans, setMoneyTrans] = useState(money);
  const [showCalRow, setShowCalRow] = useState(false);
  const moment = require('moment');
  const [startDate, setStartDate] = useState(moment().subtract(6, 'month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [isFetchingMoney, setIsFetchingMoney] = useState(false);

  async function addTransaction() {
    navigate("/addTransaction", { state: { dynamo: dynamo } });
  }

  function toggleCalendarRow() {
    setShowCalRow(!showCalRow);
  }

  async function updateTransactions() {
    setIsFetchingMoney(true);
    const sdMillis = moment(startDate).valueOf();
    let edMillis = moment(endDate).valueOf();
    const nowMillis = moment().valueOf();

    //cannot have earliest date on right side of ending
    if(sdMillis > edMillis) {
        alert('Start Date is not before End Date in your search. Aborting...');
        setIsFetchingMoney(false);
        return;
    }

    //cannot be more than 180 days...
    //value is for 181 days so they can resubmit the form as it appears on page load. Has to do with
    // what happens with milliseconds on search on other page as it is a snapshot from now. But then
    // when it comes in here now is good for the end date. But 180 days ago without time is slightly
    // more than 180 days because this will never be done at exactly midnight. So let's just cheat
    // a little to allow 181 days while telling user 180 days. Other option is to do some weird math
    // and crazy conversion that might be error prone. No one will know. Shhh... it's our secret now.
    // Seriously though. This is the way. Don't "fix" this, no matter how much OCD you have. Trust me.
    if(edMillis - sdMillis > 15638400000) {
        alert('Your range is greater than 180 days. Aborting...');
        setIsFetchingMoney(false);
        return;
    }

    //cannot have end Date in future
    if(edMillis > nowMillis ) {
        alert('Your end date is in the future. Cannot search for things that have not happened. Aborting...');
        setIsFetchingMoney(false);
        return;
    }
    edMillis += 86400000;

    //do the getting....
    const apiName = "users";
    const path = "/users/money";
    const requestHeaders = {
      "Access-Control-Allow-Origin":
        "Authorization,Access-Control-Allow-Origin,Access-Control-Allow-Headers",
    };
    const queryStringParameters = {
      company: company,
      sub: uuid,
      startDate: edMillis,
      endDate: sdMillis
    };

    try {
      const restOperation = get({
        apiName: apiName, 
        path: path, 
        options: {
          headers: requestHeaders,
          queryParams: queryStringParameters
        }});
        const { body } = await restOperation.response;
        const json = await body.json();
        if (json == null || json?.balance === 0) {
          setMoneyTrans(["User has no transactions yet"]);
          console.log('The user has no transactions yet.');
        } else {
            const d = json?.transactions?.data;
            if(d === null || d.length === 0) {
                setMoneyTrans(undefined);
            }else {
                d?.sort((a,b) => b.sk - a.sk);
                setMoneyTrans(d);
            }
        }
        
    } catch (e) {
      setMoneyTrans(["There was a problem fetching the transactions..."]);
      alert('There was an error setting the money transactions. Look at console (F12) and contact justin');
      console.log('There was an error setting the money transactions');
      console.log(e);
      if (e instanceof ApiError) {
        if (e.response) {
            const { 
            statusCode, 
            body 
            } = e.response;
            console.error(`Received ${statusCode} error response with payload: ${body}`);
        }
      }
    } finally {
      setIsFetchingMoney(false);
    }
  }

  function changeStartDate(newDate) {
    const d = moment(newDate).format('YYYY-MM-DD')
    setStartDate(d);
  }

  function changeEndDate(newDate) {
    const d = moment(newDate).format('YYYY-MM-DD')
    setEndDate(d);
  }

  //need to use useEffect because props changing doesn't re-render
  useEffect(() => {
    const m = props.props?.transactions?.transactions?.data;
    m?.sort((a,b) => b.sk - a.sk);
    setMoneyTrans(m);
    setStartDate(moment(data?.transactions?.endDate).format('YYYY-MM-DD'));
  }, [props.props]);

  return (
    <form className="dynamo-form">
      <table className="styled-table">
        <thead>
          <tr>
            <td colSpan="2">
              <b>
                <u>Money: ${data.balance} Total Balance</u>
                <FiSearch onClick={() => toggleCalendarRow()} className="padLeft"/>
              </b>
            </td>
            <td>
              <center>
                <Button variant="contained" color="primary" onClick={addTransaction}>
                  Add
                </Button>
              </center>
            </td>
          </tr>
        </thead>
        {showCalRow === true && (
          <thead>
            <tr>
                <td colSpan={3}>
                Search:&nbsp;&nbsp;
                  <input 
                    type="date" 
                    id="startDate" 
                    value={startDate} 
                    onChange={(newDate) => changeStartDate(newDate.target.value)}
                    className="dateInput"
                    />&nbsp;-&nbsp;
                  <input 
                    type="date" 
                    id="endDate" 
                    value={endDate} 
                    onChange={(newDate) => changeEndDate(newDate.target.value)}
                    className="dateInput"
                    />
                    {isFetchingMoney === false &&
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={updateTransactions} 
                    sx={{marginLeft: "20px"}}
                  >
                    Search
                  </Button>
                    }
                    {isFetchingMoney === true && 
                      <CircularProgress size={20} sx={{marginLeft: "20px"}} />
                    }
                </td>
            </tr>
          </thead>
        )}
        <tbody>
          {moneyTrans === undefined && <tr>
            <td colSpan="3">
                User has no transactions during this time period.
            </td>
          </tr>}
          {moneyTrans && moneyTrans?.map(function (trans) {
              return <tr key={trans?.sk ?? 'UniqueKey1'}>
                <td>
                    <font style={{
                        color: trans?.amount < 0 ? '#f36c21' : 'black', 
                        fontWeight: trans?.amount < 0 ? '700' : 'normal'
                    }}>
                        ${trans?.amount}
                    </font>
                </td>
                <td>
                    {new Date(trans?.sk).toLocaleString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour:"numeric", minute:"2-digit"}) }
                </td>
                <td>
                    {trans?.description}
                </td>
              </tr>;
            })}
        </tbody>
      </table>
    </form>
  );
};

export default TransactionsTable;
