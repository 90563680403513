import React from "react";
import { useForm } from "react-hook-form";
import { post, ApiError } from 'aws-amplify/api';

import "../styles/form.scss";
import "../styles/main.scss";
import Sidebar from "../components/sidebar";
import TextInputForm from "../components/text_input_form";

async function submitHandler(values) {
  const apiName = "users";
  const path = "/companies";
  const requestBody = {
    company: values.company,
    contact_email: values.email,
    contact_name: values.contactName,
    contact_phone: "+1" + values.phone,
    emp_code: values.empCode,
    payFreq: values.payFreq,
    planName: values.planName,
    wexMoneyPlanName: values.wexMoneyPlanName,
  };
  const requestHeaders = {
    "Access-Control-Allow-Origin":
      "Authorization, Access-Control-Allow-Origin,Access-Control-Allow-Headers",
  };

  try {
    const restOperation = post({
      apiName: apiName, 
      path: path, 
      options: {
        headers: requestHeaders,
        body: requestBody,
      }
    });
    await restOperation.response;
    alert("We added the company!");
  } catch (e) {
    alert("We DID NOT add the company! Please check console (F12) ad contact justin");
    console.log('We hit an error and it is below');
    console.log(e);
    if (e instanceof ApiError) {
      if (e.response) {
          const { 
          statusCode, 
          body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
      }
    }
  }
}

const AddClient = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div className="container">
      <Sidebar />
      <main>
        <h1>
          <center>Add a Client</center>
        </h1>
        <hr />
        <form onSubmit={handleSubmit(submitHandler)}>
          <TextInputForm
            fieldName="company"
            label="Company"
            id="company"
            register={register}
            error={errors.company}
          />
          <TextInputForm
            fieldName="contactName"
            label="Contact Name"
            id="contactName"
            register={register}
            error={errors.contactName}
          />
          <TextInputForm
            fieldName="email"
            label="Email"
            id="email"
            register={register}
            error={errors.email}
          />
          <TextInputForm
            fieldName="phone"
            label="Phone"
            id="phone"
            register={register}
            error={errors.phone}
            maxLength={10}
            minLength={10}
            pattern="[0-9]+" //must be all numbers - nothing else...
          />
          <TextInputForm
            fieldName="empCode"
            label="Employee Code - match WEX"
            id="empCode"
            register={register}
            error={errors.empCode}
            maxLength={6}
          />
          <TextInputForm
            fieldName="payFreq"
            label="Payroll Frequency - match WEX"
            id="payFreq"
            register={register}
            error={errors.payFreq}
          />
          <TextInputForm
            fieldName="planName"
            label="Plan Name - match WEX"
            id="planName"
            register={register}
            error={errors.planName}
          />
          <TextInputForm
            fieldName="wexMoneyPlanName"
            label="WEX Money Plan Name - match WEX"
            id="wexMoneyPlanName"
            register={register}
            error={errors.wexMoneyPlanName}
          />

          <input type="submit" />
          <input type="reset" />
        </form>
      </main>
    </div>
  );
};

export default AddClient;
