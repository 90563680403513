import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchAdminUsersFromAWS } from '../store/adminusers';
import { FiTrash2 } from "react-icons/fi";
import { del, ApiError } from 'aws-amplify/api';

import Sidebar from '../components/sidebar';
import '../styles/main.scss';
import { forceFetchAdminUsersFromAWS } from '../store/adminusers';

const ListAdminUsers = () => {

    const dispatch = useDispatch();
    const store = useSelector((state) => state.adminusers);
    const data = store.adminusers;

    const superUsers = ['oscarhedblad@gmail.com', 'justin.pfenning@gmail.com'];

    useEffect(() => {
        dispatch(fetchAdminUsersFromAWS(data));
    }, [dispatch, data]);

    function findArrayElementByName(array, searchName) {
        return array.find((element) => {
          return element.Name === searchName;
        })
      }

    async function deleteUserByUsername(username, email) {
        if(superUsers.some(user => user === email)) {
            alert('This user is marked as a super admin and cannot be deleted.');
            return;
        }
        const apiName = 'users'; 
        const path = '/portalusers';
        const requestBody = {
            'username': username
        };
        const requestHeaders = {
            "Access-Control-Allow-Origin" : "Authorization, Access-Control-Allow-Origin,Access-Control-Allow-Headers"
        };

        try {
            const restOperation = del({
                apiName: apiName, 
                path: path, 
                options: {
                    headers: requestHeaders,
                    body: requestBody,
                }
            });
            await restOperation.response;
            dispatch(forceFetchAdminUsersFromAWS());
            alert('We deleted the user!');
        } catch (e) {
            alert('We did NOT delete the user as we hit an error. Please look at console (F12) and contact justin');
            console.log('The error is below');
            console.log(e);
            if (e instanceof ApiError) {
                if (e.response) {
                    const { 
                    statusCode, 
                    body 
                    } = e.response;
                    console.error(`Received ${statusCode} error response with payload: ${body}`);
                }
              }
        }        
    }

    return (
        <div className="container">
            <Sidebar />
            <main>
                <div>
                    <center><h1>List of Admin Portal Users</h1></center>
                    <table className="styled-table">
                        <thead>
                            <tr key='header'>
                                <th>
                                    Phone
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Created Date
                                </th>
                                <th>
                                    Enabled?
                                </th>
                                <th>
                                    Delete?
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {store.adminusers && store.adminusers.Users.map(admin => (    
                                <tr key={admin.Username}>
                                    <td>{findArrayElementByName(admin.Attributes, "phone_number").Value}</td>
                                    <td>{findArrayElementByName(admin.Attributes, "email").Value}</td>
                                    <td>{admin.UserStatus}</td>
                                    <td>{admin.UserCreateDate}</td>
                                    <td>{admin.Enabled === true ? "YES" : "NO"}</td>
                                    <td><FiTrash2 onClick={() => deleteUserByUsername(admin.Username, findArrayElementByName(admin.Attributes, "email").Value)}/></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                
            </main>
        </div>
  );
}

export default ListAdminUsers;