import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { TextField } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';

const TextInputModal = (props) => {
    const {title, content, open, setOpen, onConfirm, labelText} = props;
    const [newInfo, setNewInfo] = useState("");

    function onChanged(s) {
        setNewInfo(s);
    }
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div>
                        {content}<br /><br />
                        <TextField id="outlined-basic" 
                                    label={labelText}
                                    variant="outlined" 
                                    onChange={event => {
                                        const { value } = event.target;
                                        onChanged(value);
                                    }}
                        />
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false);
                }}>
                    Cancel
                </Button>
                <Button onClick={() => {
                    onConfirm(newInfo);
                    setNewInfo("");
                    setOpen(false);
                }}>
                    OK
                </Button>
            </DialogActions>
      </Dialog>
    );
}

export default TextInputModal;