import React, { useState } from "react";
import LoginTextInput from "./login_text_input";
import "../../styles/login.css";
import logo from "../../images/AddventureLogo.png";
import { signIn } from 'aws-amplify/auth'
import { isUserLoggedIn } from "../../shared_functions";
import { useNavigate } from "react-router-dom";

const UnPwEntry = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const usernameChanged = (newEmail) => {
    setUsername(newEmail);
  };
  const passwordChanged = (newPassword) => {
    setPassword(newPassword);
  };

  async function doLogin(event) {
    event.preventDefault();

    try {
      const user = await signIn({username: username, password: password});
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        const newPass = prompt(
          "You must select a new password. Please enter it below. Must contain 1 capital letter, 1 lowercase letter, 1 number, and 1 special character. It must be 8 characters or longer."
        );
        try {
          //await Auth.completeNewPassword(user, newPass);
        } catch (ex) {
          console.log("We hit an exception...");
          console.log(ex);
          alert(ex.message);
          return;
        }
      }
    } catch (e) {
      alert(e.message);
      console.log("We hit an exception");
      console.log(e);
      return;
    }
    if (await isUserLoggedIn()) {
      navigate("/");
    }
  }

  return (
    <div className="center">
      <form>
        <img src={logo} alt="Logo" className="logoImage" />
        <LoginTextInput
          value={username}
          name="Username"
          placeholder="Username"
          type="text"
          onChange={(e) => usernameChanged(e.target.value)}
        />
        <LoginTextInput
          value={password}
          name="Email"
          placeholder="Password"
          type="password"
          onChange={(e) => passwordChanged(e.target.value)}
        />
        <button className="loginButton" onClick={doLogin}>
          Login
        </button>
        <button className="forgotButton loginButton">Forgot Password</button>
      </form>
    </div>
  );
};

export default UnPwEntry;
