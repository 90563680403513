import { createSlice } from '@reduxjs/toolkit';
import { get, ApiError } from 'aws-amplify/api';

// Slice
const slice = createSlice({
  name: 'adminusers',
  initialState: {
    adminusers: null,
    isLoading: false,
  },
  reducers: {
    updateAdminUsers: (state, action) => {
      state.adminusers = action.payload;
    },
    updateIsLoading: (state, action) => {
      state.isLoading = action.payload;
    }
  },
});
export default slice.reducer

// Actions
const { updateIsLoading, updateAdminUsers } = slice.actions
export const fetchAdminUsersFromAWS = (stored) => async dispatch => {
  if(stored != null) {
      return;
  }
  dispatch(updateIsLoading(true));
  try {
    const apiName = 'users'; 
    const path = '/portalusers';
    const requestHeaders = {
      "Access-Control-Allow-Origin" : "Authorization, Access-Control-Allow-Origin, Access-Control-Allow-Headers"
    };

    const restOperation = get({
      apiName: apiName, 
      path: path, 
      options: {
        headers: requestHeaders,
      }
    });
    const { body } = await restOperation.response;
    const json = await body.json();
    if(json != null) {
      dispatch(updateAdminUsers(json));
    }

  } catch (e) {
    alert('There was an error in fetchAdminUsersFromAWS. Look at console (F12) and talk to justin');
    console.log('The error is below');
    console.log(e);
    if (e instanceof ApiError) {
      if (e.response) {
          const { 
          statusCode, 
          body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
      }
    }
    return 'There was an error in fetchAdminUsersFromAWS';
  } finally {
    dispatch(updateIsLoading(false));
  }
}

export const forceFetchAdminUsersFromAWS = () => async dispatch => {
  dispatch(updateIsLoading(true));
  try {
    const apiName = 'users'; 
    const path = '/portalusers';
    const requestHeaders = {
      "Access-Control-Allow-Origin" : "Authorization, Access-Control-Allow-Origin, Access-Control-Allow-Headers"
    };

    const restOperation = get({
      apiName: apiName, 
      path: path, 
      options: {
        headers: requestHeaders,
      }
    });
    const {body} = await restOperation.response;
    const json = await body.json();
    if(json != null) {
      const pureJson = JSON.parse(json);
      dispatch(updateAdminUsers(pureJson));
    }
  } catch (e) {
    alert('Error force fetching admin users. Please look at console (F12) and contact justin');
    console.log('We got an error forceFetching the admin users. Error below');
    console.log(e);
    if (e instanceof ApiError) {
      if (e.response) {
          const { 
          statusCode, 
          body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
      }
    }
    return console.error(e.message);
  } finally {
    dispatch(updateIsLoading(false));
  }
}