const config = {
  apiGateway: {
    URL: "https://gkn2pw10l8.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_yz8Yszhyw",
    APP_CLIENT_ID: "3a20eteputi5dq0h1ln38qq3o3",
  },
};

export default config;
