import React from "react";

import "../styles/main.scss";
import Sidebar from "../components/sidebar";

const AddUser = () => {
  return (
    <div className="container">
      <Sidebar />
      <main>
        <h1>
          <center>Add a User - Mobile App</center>
        </h1>
        <hr />
        <center>
          <h3>Use the file upload instead of this</h3>
        </center>
      </main>
    </div>
  );
};

export default AddUser;
