import React from 'react';
import '../../styles/main.scss';

const TripTable = (props) => {

    const data = props.props;

    if(data === 'User has no trips set yet') {
        return (
            <div>
                <table className="styled-table">
                <thead >
                <tr>
                    <td colSpan='1'>
                        <b><u>Trips</u></b>
                    </td>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <b>No trips set yet</b>
                </td>
            </tr>
            </tbody>
                </table>
            </div>
        );
    }

    return (
        <div>
        <table className="styled-table">
            <thead >
                <tr>
                    <td colSpan='3'>
                        <b><u>{data.name}</u></b>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <b>Start: </b>{data.startDate}
                    </td>
                    <td>
                        <b>End: </b>{data.endDate}
                    </td>
                    <td>
                        <b>Cost: </b>${data.cost}
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>City: </b>{data.city}
                    </td>
                    <td colSpan='2'>
                        <b>Updated At: </b>{data.updatedAt}
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Country: </b>{data.country}
                    </td>
                    <td colSpan='2'>
                        <b>Created At: </b>{data.createdAt}
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Images </b>(left is small; right is large)
                    </td>
                    <td>
                        <a href={data.smallImageUrl} target='_blank' rel='noreferrer'>
                            <img src={data.smallImageUrl} height='50' alt='Small'/>
                        </a>
                    </td>
                    <td>
                    <a href={data.imageUrl} target='_blank' rel='noreferrer'>
                            <img src={data.imageUrl} height='50' alt='Large'/>
                        </a>
                    </td>

                    
                </tr>
                
            </tbody>
        </table>
        <br />
        </div>
        
    );
}

export default TripTable;